import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setAuthenticated } from '../../actions';

const Wrapper = styled.div `

	height: 500px;

	input[type="text"] {
	  width: 100%;
	  border:none;
	  padding-left: 0.75rem;
	  transition: all .1s ease;
	}

	.input-field {
	  position: relative;
	  width: 25%;
	  margin: 0 auto;
	  top: 50%;
    transform: translateY(-50%);

		label {
		  position: absolute;
		  left: 0;
		  color: grey;
		  transition: all .15s ease;
		  padding: 0px; 
		  pointer-events: none;
		  width: 100%;
	    padding-left: 0.75rem;
	    border-left: 1px solid #b2b2b2;
	    border-right: 1px solid #b2b2b2;
		}

		.outlined {
		  height: 38px;
		  box-shadow: inset 0 0 0 1px #b2b2b2;
		  border-radius: 4px;
		  background: transparent;

		  &+label {
				background: white;
		  	top: 50%;
    		transform: translateY(-50%);
		  }

		  &:focus {
		  	outline: none;
	  		box-shadow: inset 0 0 0 2px #505860;

	  		&+label {
	  			color: #505860;
				  transform: scale(.95) translateY(-150%) translateX(5px);
				  padding: 0 5px;
				  width: unset;
    			border: none;
	  		}

	  		&.error {
	  			box-shadow: inset 0 0 0 2px #B33A3A;

	  			&+label {
	  				color: #B33A3A;
	  			}
	  		}
		  }
		}
	}
`;

const passwords = [
	{ user: 'Jihyun', password: 'jiji' }
];

class LogIn extends React.Component {

	constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
	}

	onChange(e) {
		if(e.target.value === '') {
			this.refs.input.classList.remove('error');
		}
	}

	onKeyDown(e) {
		if( e.keyCode === 13 ) { // return key
      const found = passwords.find( p => p.password === e.target.value );
      if(typeof found !== 'undefined') {
      	this.props.dispatch(setAuthenticated(found.user));
      	this.props.history.push('/work');
      }
      else {
	      this.refs.input.classList.add('error');
      }
    }
	}

  render() {
    return (
      <Wrapper>
        <div className="input-field">
			    <input ref='input' type='text' className='outlined' spellCheck='false' 
			    	onChange={this.onChange}
			    	onKeyDown={this.onKeyDown} />
			    <label>Password</label>
			  </div>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  }
}

export default connect(
  null,
  mapDispatchToProps
)(LogIn)
