import dataProjects from '../assets/data/projects.json';
// import dataDoodles from '../assets/data/doodles.json';
import * as g from '../constants';


export function loadData() {
	const publicProjects = dataProjects.filter(p => {
		if(p.publish) return true;
		return false;
	});

	const projects = publicProjects.map(p => {
		return require(`../assets/data/projects/${p.file}`);
	});

	// const doodles = dataDoodles.map(d => {
	// 	return require(`../assets/data/doodles/${d.file}`);
	// });

  return {
    type: g.INITIAL_LOAD,
    projects: projects,
    // doodles: doodles
  }
}

export function findByProjectId(array, id) {
	const found = array.filter(p => {
    if (p.id === id) return true;
    return false;
  });

  if(found.length) {
  	return found[0];
  }
  return null;
}

export function getPrevByProjectId(array, id) {
	const project = findByProjectId(array, id);
	if(project === null) return null;

	var index = array.indexOf(project);
	if(index > 0) {
		return array[index-1];
	}
	return null;
}

export function getNextByProjectId(array, id) {
	const project = findByProjectId(array, id);
	if(project === null) return null;

	var index = array.indexOf(project);
	if(index < array.length - 1) {
		return array[index+1];
	}
	return null;
}

export function setAuthenticated(username) {
	return {
		type: g.SET_AUTHENTICATED,
		username: username
	}
}

export function toggleAuthenticated() {
	return {
		type: g.TOGGLE_AUTHENTICATED
	}
}

export function setShuffleDoodle(status) {
	return {
		type: g.SHUFFLE_DOODLE,
		status: status
	}
}

export function setRefreshDoodle(status) {
	return {
		type: g.REFRESH_DOODLE,
		status: status
	}
}

export function setTheme(theme) {
	return {
		type: g.SET_THEME,
		theme: theme
	}
}

export function setMobile() {
	return {
		type: g.MOBILE
	}
}

export function changeLocation(location) {
	return {
		type: g.CHANGE_LOCATION,
		location: location
	}
}