import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ThumbnailGridView from '../../components/ThumbnailGridView';

const Wrapper = styled.div `

`;

class Work extends React.Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
      <Wrapper className='work'>
        <ThumbnailGridView list={this.props.projects} isAuthenticated={this.props.isAuthenticated}/>
        {/*<ThumbnailGridView list={this.props.projects.filter( p => this.props.isAuthenticated ? true : p.public )} />*/}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  var g = state.get('global');
  return {
    projects: g.projects,
    isAuthenticated: g.isAuthenticated
  }
}

export default connect(
  mapStateToProps,
  null
)(Work)
