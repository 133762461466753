import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div `
  display: inline-block;
  float: right;
  vertical-align: top;

    // &.doodle {
    //     &.dark {
    //         .item {
    //             color: #fefefe;
    //             border-bottom-color: #fefefe;

    //             &.selected {
    //                 border-bottom-color: #fefefe;
    //             }
    //         }
    //         a, a.hover {
    //           color: #fefefe !important;
    //         }
    //     }
    // }

    &[type='work'] {
      .item[type='work'] {
        border-bottom: 1px solid #555;
      }
    }
    &[type='about'] {
      .item[type='about'] {
        border-bottom: 1px solid #555;
      }
    }

  .item {
    display: inline-block;
    margin-right: 15px;
    color: #555;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  a, a:hover {
    color: #555 !important;
    border-bottom: none;
  }

  @media (max-width:419px) {
    float: left;
    margin-top: 16px;
  }

`;

class Menu extends React.Component {
    render() {
        const location = this.props.location === '' ? 'work' : (this.props.location.indexOf('/') !== -1 ? this.props.location.substr(0, this.props.location.indexOf('/')) : this.props.location)
        return (
          <Wrapper type={location} className={this.props.theme}>
            <div type='work' className='item'>
              <Link to={{ pathname: `/work` }}>{'work'}</Link>
            </div>
            <div type='about' className='item'>
              <Link to={{ pathname: `/about` }}>{'about'}</Link>
            </div>
            <div className='item'>
              <a href="https://jihyunmakes.tumblr.com" target="_blank" rel="noopener noreferrer">lab</a>
            </div>
            <div className='item'>
              <a href="https://github.com/jihyunlee" target="_blank" rel="noopener noreferrer">code</a>
            </div>
          </Wrapper>
        );
    }
}

Menu.propTypes = {
    location: PropTypes.string,
    theme: PropTypes.string
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    }
}

export default connect(
    null,
    mapDispatchToProps
)(Menu)
