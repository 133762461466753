import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div `
`;

class NotFound extends React.Component {
  render() {
    return (
      <Wrapper>
      	Uh, oh.. 
      </Wrapper>
    );
  }
}

export default connect(
  null,
  null
)(NotFound)
