
export const INITIAL_LOAD	= 'INITIAL_LOAD';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const TOGGLE_AUTHENTICATED = 'TOGGLE_AUTHENTICATED';
export const MOBILE = 'MOBILE';
export const CHANGE_LOCATION = 'CHANGE_LOCATION';

export const SHUFFLE_DOODLE = 'SHUFFLE_DOODLE';
export const REFRESH_DOODLE = 'REFRESH_DOODLE';
export const SET_THEME = 'SET_THEME';
