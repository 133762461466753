import * as g from '../constants';

// The initial state of the App
const initialState = {
	projects: [],
  // doodles: [],
  // refreshDoodle: false,
  // shuffleDoodle: false,
  isAuthenticated: false,
  username: '',
  pathname: '',
  // theme: 'light',
  mobile: false
};

function appReducer(state = initialState, action) {
  switch (action.type) {

    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.pathname.substring(1)
      }

    case g.CHANGE_LOCATION:
      return {
        ...state,
        pathname: action.location.pathname.substring(1)
      }

    case g.INITIAL_LOAD:
     	return {
     		...state,
     		projects: action.projects,
        // doodles: action.doodles
     	}

    case g.SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
        username: action.username
      }

    case g.TOGGLE_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: !state.isAuthenticated
      }

    // case g.SHUFFLE_DOODLE:
    //   return {
    //     ...state,
    //     shuffleDoodle: action.status
    //   }
    // case g.REFRESH_DOODLE:
    //   return {
    //     ...state,
    //     refreshDoodle: action.status
    //   }

    // case g.SET_THEME:
    //   return {
    //     ...state,
    //     theme: action.theme
    //   }

    case g.MOBILE:
      return {
        ...state,
        mobile: true
      }

    default:
      return state;
  }
}

export default appReducer;