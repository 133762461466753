import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import imgPlaceholder from '../../assets/images/thumb-placeholder.png';

const Wrapper = styled.div `
    .item {
        display: inline-block;
        width: 300px;
        height: 300px;
        margin-right: 30px;
        margin-bottom: 30px;
        background-color: #eee;
        position: relative;

        &:nth-of-type(3n) {
            margin-right: 0;
        }

        img, .project{
            position: absolute;
            left: 0;
            top: 0;
        }

        img {
            width: 100%;
        }
        .project{
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.8);
            color: rgba(255,255,255,0.8);
            opacity: 0;
            transition: opacity .2s ease;
            padding: 50px 30px;
            font-weight: 300;
            font-size: 14px;

            .title {
                font-family: Courier New;
                font-size: 20px;
                // line-height: 1.2;
                // letter-spacing: 1px;
                color: rgba(255,255,255,0.9);
            }
            .title, .year {
                margin-bottom: 10px;
            }
        }

        .lock {
            fill: #555;
            width: 15%;
            height: 15%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }

    a:hover {
        .project {
            opacity: 1;  
        }
    }

    a, a:hover {
        border-bottom: none;
    }

    @media (max-width:1023px) {
        .item {
            width: 220px;
            height: 220px;
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }
    @media (max-width:767px) {
        .item {
            width: 48%;
            height: auto;
            padding-top: 48%;
            margin-right: 4%;
            margin-bottom: 20px;

            &:nth-of-type(3n) {
                margin-right: 4%;
            }
            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }
    }
    @media (max-width:419px) {
        .item {
            width: 100%;
            height: auto;
            padding-top: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
`;

class ThumbnailGridView extends React.Component {
    constructor(props) {
        super(props);

        this.checkVisibility = this.checkVisibility.bind(this);
        this.createElement = this.createElement.bind(this);
    }

    checkVisibility(project) {
        return project.public || (!project.public && this.props.isAuthenticated);
    }

    createElement(project) {
        if(typeof this.props.focusProjectId === 'undefined' || (this.props.focusProjectId !== project.id)) {
            if(this.checkVisibility(project)) {
                return (
                    <Link 
                        className='item' 
                        key={project.id}
                        to={{pathname: `/work/${project.id}`, state: { project: project } }}>
                        <picture>
                            {typeof project.thumb.webp !== 'undefined' &&
                            <source type='image/webp' media="(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)" 
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.medium.two} 2x`} />}
                            {typeof project.thumb.webp !== 'undefined' &&
                            <source type='image/webp' media="(min-width: 768px) and (max-width: 1024px)" 
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.small.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.small.two} 2x`} />}
                            {typeof project.thumb.webp !== 'undefined' &&
                            <source type='image/webp' media="(min-width: 320px) and (max-width: 480px)" 
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.large.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.large.two} 2x`} />}
                            {typeof project.thumb.webp !== 'undefined' &&
                            <source type='image/webp' media="(min-width: 481px) and (max-width: 812px)" 
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.medium.two} 2x`} />}
                            {typeof project.thumb.webp !== 'undefined' &&
                            <source type='image/webp'
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.webp.medium.two} 2x`} />}
                            <source type={project.thumb.default.type} media="(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)" 
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.default.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.default.medium.two} 2x`} />
                            <source type={project.thumb.default.type} media="(min-width: 768px) and (max-width: 1024px)" 
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.default.small.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.default.small.two} 2x`} />
                            <source type={project.thumb.default.type} media="(min-width: 320px) and (max-width: 480px)" 
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.default.large.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.default.large.two} 2x`} />
                            <source type={project.thumb.default.type} media="(min-width: 481px) and (max-width: 812px)" 
                                data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.thumb.default.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.thumb.default.medium.two} 2x`} />
                            <img className='lazyload'
                                loading='lazy'
                                src={imgPlaceholder}
                                data-src={`${process.env.PUBLIC_URL}/static/assets/images/${project.thumb.default.medium.one}`} 
                                data-srcset={`${process.env.PUBLIC_URL}/static/assets/images/${project.thumb.default.medium.two} 2x`} 
                                alt={project.id+'-thumb'} />
                        </picture>
                        <div className='project'>
                            <div className='title'>{project.title}</div>
                            <div className='year'>{project.year}</div>
                            <div className='summary'>{project.summary}</div>
                        </div>
                    </Link>
                );
            }
            else {
                if(typeof project.lock !== 'undefined') {
                    return (
                        <Link 
                            className='item' 
                            key={project.id}
                            to={{pathname: `/login`}}>
                            <picture>
                                {typeof project.lock.webp !== 'undefined' &&
                                <source type='image/webp' media="(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)" 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.webp.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.webp.medium.two} 2x`} />}
                                {typeof project.lock.webp !== 'undefined' &&
                                <source type='image/webp' media="(min-width: 768px) and (max-width: 1024px)" 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.webp.small.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.webp.small.two} 2x`} />}
                                {typeof project.lock.webp !== 'undefined' &&
                                <source type='image/webp' media="(min-width: 320px) and (max-width: 480px)" 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.webp.large.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.webp.large.two} 2x`} />}
                                {typeof project.lock.webp !== 'undefined' &&
                                <source type='image/webp' media="(min-width: 481px) and (max-width: 812px)" 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.webp.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.webp.medium.two} 2x`} />}
                                {typeof project.lock.webp !== 'undefined' &&
                                <source type='image/webp'
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.webp.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.webp.medium.two} 2x`} />}
                                <source type={project.lock.default.type} media="(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)" 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.default.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.default.medium.two} 2x`} />
                                <source type={project.lock.default.type} media="(min-width: 768px) and (max-width: 1024px)" 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.default.small.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.default.small.two} 2x`} />
                                <source type={project.lock.default.type} media="(min-width: 320px) and (max-width: 480px)" 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.default.large.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.default.large.two} 2x`} />
                                <source type={project.lock.default.type} media="(min-width: 481px) and (max-width: 812px)" 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/images/${project.lock.default.medium.one} 1x, ${process.env.PUBLIC_URL}/static/images/${project.lock.default.medium.two} 2x`} />
                                <img className='lazyload'
                                    loading='lazy'
                                    src={imgPlaceholder}
                                    data-src={`${process.env.PUBLIC_URL}/static/assets/images/${project.lock.default.medium.one}`} 
                                    data-srcset={`${process.env.PUBLIC_URL}/static/assets/images/${project.lock.default.medium.two} 2x`} 
                                    alt={project.id+'-lock'} />
                            </picture>
                        </Link>
                    );
                }                    
            }
        }

        return;
    }

    render() {
        return (
            <Wrapper>
                {this.props.list.map( p => this.createElement(p) )}
            </Wrapper>
        );
    }
}

ThumbnailGridView.propTypes = {
    list: PropTypes.array,
    focusProjectId: PropTypes.string,
    isAuthenticated: PropTypes.bool
}

export default ThumbnailGridView;