import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// import DoodleTitle from '../../components/DoodleTitle';
import Logo from '../../components/Logo';
import Menu from '../../components/Menu';

// import { setShuffleDoodle } from '../../actions';

const Wrapper = styled.div `
	margin: 60px 0 45px;

  @media (max-width:419px) {
    margin-bottom: 60px;
  }

  // &.doodle {
  //   position: absolute;
  //   bottom: 15%;

  //   .backdrop {
  //     opacity: 0;
  //     background: rgba(255,255,255,0.7);
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     left: 0;
  //     top: 0;
  //     filter: blur(5px);
  //     z-index: -1;
  //   }
  }
`;

class Header extends React.Component {
  // constructor(props) {
  //   super(props);

    // this.onDoodleClick = this.onDoodleClick.bind(this);
  // }

  // onDoodleClick() {
  //   this.props.dispatch(setShuffleDoodle(true));
  // }

  render() {
    return (
      <Wrapper className={this.props.pathname === '' ? 'work' : ''}>
        <Logo location={this.props.pathname} theme={this.props.theme} />
        {/*this.props.pathname === '' && <DoodleTitle what={'noise'} onClick={this.onDoodleClick} />*/}
        <Menu location={this.props.pathname} theme={this.props.theme} />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  var g = state.get('global');
  return {
    pathname: g.pathname,
    theme: g.theme,
  }
}

export default connect(
  mapStateToProps,
  null
)(Header)
