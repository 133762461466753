import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { isObjectEquivalent } from '../../utils';

import NavBar from '../../components/NavBar';
import ThumbnailGridView from '../../components/ThumbnailGridView';
// import { TemplateContent } from '../../components/ProjectTemplate'
const TemplateContent = lazy(() => import ('../../components/ProjectTemplate/TemplateContent'));

const Wrapper = styled.div `
	margin-top: 50px;
	font-size: 16px;

	[class*="col"] {
	    display: inline-block;
    	vertical-align: top;
	}

	.col8 {
		width: 66.6666%;
	}
	.col4 {
		width: 33.3333%;
		float: right;
	}

	.meta {
		.confidential {
			color: red;
			text-transform: uppercase;
		}

		.title {
			font-size: 21px;
			font-family: Courier New;
			text-transform: capitalize;
			color: #666;
			margin-bottom: 10px;
            letter-spacing: -1px;
			// line-height: 1.2;
		}

		.item {
			font-size: 14px;
			line-height: 1.2;
			margin-bottom: 10px;

			.label {
				color: #888;
			}
		}

		.summary {
			font-style: italic;
		}
		.description {				
			.paragraph {
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.project {
		width: 100%;
		margin-bottom: 50px;
		position: relative;
		display: inline-block;
	}

	@media (max-width:419px) {
    	[class*="col"] {
    		width: auto;
    	}
    	.details {
    		float: unset;
    		margin-bottom: 40px;

	    	.meta {
	    		padding-left: 0;
	    	}
    	}
	}
`;

class Project extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            project: {}
        }

        this.onBack = this.onBack.bind(this);
        this.onGoToProject = this.onGoToProject.bind(this);
        this.getProjectFromLocation = this.getProjectFromLocation.bind(this);
    }

    componentWillMount() {
        var project = this.getProjectFromLocation();
        if (project === null) {
            this.props.history.push('/uh-oh');
            return;
        }

        this.setState({
            // projects: this.props.projects.filter( p => this.props.isAuthenticated ? true : p.public ),
            projects: this.props.projects,
            project: project
        })
    }

    componentWillReceiveProps(nextProps) {
        if (typeof nextProps.location.state === 'undefined') {
            const project = this.getProjectFromLocation();
            if (project === null) {
                return;
            }

            this.setState({
                project: project
            });
            return;
        }

        if (typeof this.props.location.state === 'undefined' ||
            !isObjectEquivalent(this.props.location.state, nextProps.location.state)) {
            this.setState({
                project: nextProps.location.state.project
            });
            window.scrollTo(0, 0);
        }
    }

    getProjectFromLocation() {
        var project = {};
        if (typeof this.props.location.state === 'undefined') {
            if (this.props.location.pathname.indexOf('/work/') === -1) {
                return null;
            }

            var projectId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1, this.props.location.pathname.length);
            const found = this.props.projects.filter(p => p.id === projectId);
            if (found.length === 0) {
                return null;
            }

            project = found[0];
        } else {
            project = this.props.location.state.project
        }

        return project;
    }

    onBack() {
        this.props.history.push('/work');
    }

    onGoToProject(proj) {
        if (proj !== null) {
            this.setState({
                project: proj
            });
            window.scrollTo(0, 0);
            this.props.history.push('/work/' + proj.id);
        }
    }

    render() {
    	const { project } = this.state;
        return (
            <Wrapper>
  				{typeof project.template !== 'undefined' && project.template === 'content' &&
                    <Suspense fallback={<div></div>}>
      					<TemplateContent project={project} />
                    </Suspense>
  				}
		      	<NavBar 
		      		projects={this.state.projects}
		      		project={project}
		      		onBack={this.onBack}
		      		onGoToProject={this.onGoToProject}
		      	/>
		      	<ThumbnailGridView 
		      		list={this.state.projects} 
		      		focusProjectId={project.id}
		      		isAuthenticated={this.props.isAuthenticated} />
      		</Wrapper>
        );
    }
}

const mapStateToProps = (state) => {
    var g = state.get('global');
    return {
        projects: g.projects,
        isAuthenticated: g.isAuthenticated
    }
}

export default connect(
    mapStateToProps,
    null
)(Project)