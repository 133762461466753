import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Svg = styled.svg `
	@keyframes dash {
		to {
			stroke-dashoffset: 0;
		}
	}

	@keyframes filling {
		0%, 60% {
			fill: #222;
			fill-opacity: 0;
			stroke-opacity: 1;
		}
		100% {
			fill: #222;
			fill-opacity: 1;
			stroke-opacity: 0;
		}
	}

    width: 100%;
    height: 100%;
    stroke: #222;
    stroke-width: 1;
    stroke-dasharray: 500;
	stroke-dashoffset: 500;

	&.animate {
		animation: dash 8s linear forwards, filling 3s ease-in forwards;
	}
`;

class SvgAnimatingText extends React.Component {
	constructor(props) {
        super(props)

        this.state = {
            isAnimating: false
        }

        this.onAnimationEnd = this.onAnimationEnd.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if(!this.state.isAnimating) {
            this.setState({
                isAnimating: true
            });        
        }
    }

    onAnimationEnd() {
    	this.setState({
                isAnimating: false
            });
        if(this.state.isAnimating) {
            
        }
    }

	render() {
		const { isAnimating } = this.state;
		return (
			<Svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox='0 0 150 35' className={isAnimating ? 'animate' : ''} onClick={this.onClick} onAnimationEnd={this.onAnimationEnd}>
		        <text dominantBaseline="middle" textAnchor="left" x="0" y="50%">JIHYUN LEE</text>
			</Svg>
		);
	}
}

SvgAnimatingText.propTypes = {
    isAnimating: PropTypes.bool
}

export default SvgAnimatingText;