import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div `
  margin-bottom: 60px;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 1px;
  color: #888;

  .name {
    text-transform: uppercase;
  }

  // &.cover {
  //   display: none;
  // }
`;

class Footer extends React.Component {
  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <Wrapper className={this.props.pathname === '' ? 'cover' : ''}>
        <span className='copyright'>{'Copyright © '}</span>
        <span className='year'>{this.getYear() + ' '}</span>
        <span className='name'>{'Jihyun Lee.'}</span>
        <span className='reserved'>{' All rights reserved.'}</span>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  var g = state.get('global');
  return {
    pathname: g.pathname
  }
}

export default connect(
  mapStateToProps,
  null
)(Footer)
