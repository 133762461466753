import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div `
	width: 50%;
	margin-bottom: 50px;

	.letter {
		margin-bottom: 20px;
	}

	.paragraph {
		margin-bottom: 15px;

		&.code {
			font-family: Courier New;

			.tab2 {
				margin-left: 20px;
			}
			.tab4 {
				margin-left: 40px;
			}
		}
	}

	@media (max-width:419px) {
    width: 100%;
  }
`;

class About extends React.Component {
  render() {
    return (
      <Wrapper>
      	<div className='letter'>
	        <div className='paragraph'>Hello stranger!</div>
					<div className='paragraph'>My name is Jihyun Lee. I am a creative technologist based in New York City and interested in exploring the creative use of art and emerging technology into people's lives.</div>
					<div className='paragraph'>
						I am a UX Engineer at <a href='https://www.google.com/' target='_blank' rel='noopener noreferrer'>Google</a>. Previously, I worked at <a href='https://www.framestore.com/' target='_blank' rel='noopener noreferrer'>Framestore</a>, <a href='https://www.themill.com/' target='_blank' rel='noopener noreferrer'>The Mill</a>, <a href='https://www.bluecadet.com/' target='_blank' rel='noopener noreferrer'>Bluecadet</a>, <a href='https://www.intersection.com/' target='_blank' rel='noopener noreferrer'>Intersection</a>(formerly Control Group), <a href='http://fastcompany.com' target='_blank' rel='noopener noreferrer'>Fast Company</a> and <a href='http://samsung.com' target='_blank' rel='noopener noreferrer'>Samsung</a> and
						was a research resident fellow at NYU <a href='http://itp.nyu.edu/itp/' target='_blank' rel='noopener noreferrer'>Interactive Telecommunications Program (ITP)</a><span>{' '}</span>
						and an <a href='http://iac.com' target='_blank' rel='noopener noreferrer'>IAC</a> fellow at <a href='http://about.com' target='_blank' rel='noopener noreferrer'>About.com</a>.
					</div>
				</div>
				<div className='paragraph code'>
					<div>{'say_hello_to_me: {'}</div>
					{/*<div>{"{"}</div>*/}
					<div className='tab4'><span>email: </span><a href='mailto:say@hellojihyun.com'>say@hellojihyun.com</a><span>,</span></div>
					{/*<div className='tab2'><span>follow: [</span></div>*/}
					<div className='tab4'><span>LinkedIn: </span><a href='http://www.linkedin.com/in/hellojihyun' target='_blank' rel='noopener noreferrer'>@hellojihyun</a><span>,</span></div>
					<div className='tab4'><span>GitHub: </span><a href='https://github.com/jihyunlee' target='_blank' rel='noopener noreferrer'>@jihyunlee</a><span>,</span></div>
					<div className='tab4'><span>Twitter: </span><a href='https://twitter.com/heyjihyun' target='_blank' rel='noopener noreferrer'>@heyjihyun</a></div>
					{/*<div className='tab4'><span>Vimeo: </span><a href='http://vimeo.com/jihyunlee' target='_blank' rel='noopener noreferrer'>@jihyunlee</a></div>*/}
					{/*<div className='tab4'><span>about.me: </span><a href='http://about.me/jihyun.lee' target='_blank' rel='noopener noreferrer'>@jihyun.lee</a></div>*/}
					{/*<div className='tab2'>]</div>*/}
					<div>}</div>
				</div>
				<div className='paragraph code'>{`//! Resume available upon request`}</div>
      </Wrapper>
    );
  }
}

export default connect(
  null,
  null
)(About)
