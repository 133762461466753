import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SvgAnimatingText from '../../assets/svg/SvgAnimatingText';

const Wrapper = styled.div `
    display: inline-block;
    font-family: sans-serif;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    position:relative;
    padding-right: 2px;
    cursor: pointer;

    .text-container {
        display: inline-block;
        width: 150px;
        height: 35px;
    }

    @media (max-width:419px) {
        display: block;
    }

    a, a:hover {
        border-bottom: none;
    }
`;

class Logo extends React.Component {
    render() {
        const location = this.props.location === '' ? 'work' : '';
        return (
            <Wrapper data-text='Jihyun Lee' className={this.props.theme+ ' ' + location}>
                <Link to={{ pathname: `/` }}>
                    <div className='text-container'>
                        <SvgAnimatingText />
                    </div>
                </Link>
            </Wrapper>
        );
    }
}

Logo.propTypes = {
    location: PropTypes.string,
    theme: PropTypes.string
}

export default Logo;