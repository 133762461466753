import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import About from './containers/About';
// import Doodle from './containers/Doodle';
import Header from './containers/Header';
import Footer from './containers/Footer';
import LogIn from './containers/LogIn';
import NotFound from './containers/NotFound';
import Project from './containers/Project';
import Work from './containers/Work';

import { loadData, setMobile, toggleAuthenticated, changeLocation } from './actions'

const Wrapper = styled.div `
    max-width: 960px;
    margin: 0 auto;
    font-size: 14px;

    @media (max-width:1023px) {
        max-width: 700px;
    }
    @media (max-width:767px) {
        width: 100%;
        padding: 0 30px;
    }
`;

class App extends Component {
    constructor(props) {
        super(props);

        this.initializeReactGA();

        document.addEventListener('keydown', (e) => this.onKeydown(e));
    }

    componentWillMount() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.props.dispatch(setMobile());
        }

        this.props.dispatch(loadData());
    }

    componentDidUpdate(prevProps) {
        if (this.props.pathname !== prevProps.pathname) {
            window.scrollTo(0, 0);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.props.dispatch(changeLocation(nextProps.location));
    }

    initializeReactGA() {
        ReactGA.initialize('UA-29305204-2');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    onKeydown(e) {
        const activeEl = document.activeElement;
        const tagName = activeEl.tagName;
        if ((tagName === 'INPUT' || tagName === 'TEXTAREA') && !activeEl.readOnly) {
            return;
        }

        var charCode = (typeof e.which === 'number') ? e.which : e.keyCode;
        if (charCode) {
            var key = String.fromCharCode(charCode);
            if (key === 'A') {
                this.props.dispatch(toggleAuthenticated());
            }
        }
    }

    render() {
        return (
            <Wrapper className={this.props.pathname === '' ? 'work' : this.props.pathname}>
                <Header />
                <Switch>
                    <Route exact path="/" component={Work} />
                    <Route exact path="/work" component={Work} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/work/:id" component={Project} />
                    <Route exact path="/login" component={LogIn} />
                    <Route component={NotFound} />
                </Switch>
                <Footer />
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => {
    var g = state.get('global');
    return {
        projects: g.projects,
        pathname: g.pathname
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));