import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getPrevByProjectId, getNextByProjectId } from '../../actions';

const Wrapper = styled.div `
  position: relative;
  margin-bottom: 50px;
  color: #555;

  .back, .controls {
    display: inline-block;
    cursor: pointer;
  }

  .controls {
    float: right;

    div {
      display: inline-block;
    }

    .prev, .next {
      padding: 0 5px;
      letter-spacing: 1px;

      &.inactive {
        color: #ccc;
        cursor: default;
      }
    }
  }
`;

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.onBack = this.onBack.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
    this.getPrev = this.getPrev.bind(this);
    this.getNext = this.getNext.bind(this);
  }

  onBack() {
    this.props.onBack();
  }

  getPrev() {
    return getPrevByProjectId(this.props.projects, this.props.project.id);
  }

  getNext() {
    return getNextByProjectId(this.props.projects, this.props.project.id);
  }

  onPrev() {
    const prev = this.getPrev();
    if(prev !== null) {
      this.props.onGoToProject(prev);
    }
  }

  onNext() {
    const next = this.getNext();
    if(next !== null) {
      this.props.onGoToProject(next);
    }
  }

  render() {
    return (
      <Wrapper>
        <div className='back' onClick={this.onBack}>back to work</div>
        <div className='controls'>
          <div className={'prev' + (this.getPrev() === null ? ' inactive' : '')} onClick={this.onPrev}>prev</div>
          <div className='slash'>/</div>
          <div className={'next' + (this.getNext() === null ? ' inactive' : '')} onClick={this.onNext}>next</div>
        </div>
      </Wrapper>
    );
  }
}

NavBar.propTypes = {
  projects: PropTypes.array,
  project: PropTypes.object,
  onBack: PropTypes.func,
  onGoToProject: PropTypes.func
}

export default NavBar;
