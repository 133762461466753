import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import createHistory from 'history/createBrowserHistory';
// import { ConnectedRouter } from 'react-router-redux';
import { HashRouter } from 'react-router-dom'
import configureStore from './store';
import registerServiceWorker from './registerServiceWorker';

import App from './App';

const initialState = {};
// const history = createHistory({
// 	basename: process.env.PUBLIC_URL,
// });

const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
